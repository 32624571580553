import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <div className="w-full border-t border-solid border-customOrange-100 p-4 mt-4 flex justify-around flex-col sm:flex-row items-center gap-8">
      <div>Corentin Casset</div>
      <div className="flex gap-4 flex-wrap items-center justify-center">
        <Link to="/#">Mentions légales</Link>
        <p className="hidden md:block">-</p>
        <Link to="/#">Politique de confidentialité</Link>
      </div>
      <button
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        className="p-2 rounded-full outline outline-1 outline-customOrange-100 items-center justify-center hover:bg-customOrange-100 hover:text-black transition-all hover:cursor-pointer text-lg hidden md:flex"
      >
        <FontAwesomeIcon
          icon={faArrowUp}
          height={10}
          width={16}
          color="#FFB87A"
          className="hover:text-black transition-all"
        />
      </button>{" "}
    </div>
  );
}

export default Footer;
