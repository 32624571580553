import React from "react";
import Card from "./Card";
import Button from "../components/Button";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";

function Realisations() {
  return (
    <div className="lg:mx-48 lg:my-24 mx-2 lg:mt-44 flex flex-col gap-20">
      <h2 className="text-2xl sm:text-4xl text-center sm:text-left font-semibold">
        Mes Réalisations
      </h2>
      <div className="flex justify-center gap-8 flex-wrap items-center">
        <Card
          title={"Hysequa"}
          width={290}
          height={500}
          image={"/hysequa.png"}
          linkPath={"/projets/1"}
          categories={["Wordpress", "PHP", "APIs"]}
          content={
            "Design et intégration d'un site internet sous Wordpress. J'ai aussi eu pour mission de maintenir les différents sites qu'ils possédaient déjà. J'ai aussi pu mettre en place un plugin Wordpress pour aider à la rédaction de contenu (API ChatGPT)."
          }
        />
        <Card
          title={"Transports en temps réel"}
          width={290}
          height={500}
          image={"/react.svg"}
          linkPath={"/projets/3"}
          categories={["React", "APIs", "Python"]}
          content={
            "Intégration d'un carte interactive ainsi que la mise en place d'une api personnalisée pour récupérer et mettre à jour en temps réel les transports en commun."
          }
        />
        <Card
          title={"Plugin Wordpress SEO"}
          width={290}
          height={500}
          image={"/wordpress.svg"}
          linkPath={"/projets/4"}
          categories={["PHP", "Wordpress", "APIs"]}
          content={
            "Réalisation d'un plugin Wordpress qui a pour but de : Créer automatiquement une liste d'articles corrects pour le référencement, à partir d'un prompt. Il les rédigent et se planifient automatiquement automatiquement"
          }
        />
      </div>
      <div className="w-full flex justify-center">
        <Button icon={faArrowRight} href={"/projets"}>
          Voir plus
        </Button>
      </div>
    </div>
  );
}

export default Realisations;
