import React, { useState, useEffect } from "react";
import competences from "../database/competences.json";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Bubble from "../components/Bubble";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
function Competences() {
  let { id } = useParams();
  id = parseInt(id, 10); // Convert the id to a number
  const competence = competences.find((item) => item.id === id);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col items-center">
        <Bubble height={600} width={600} top={10} />
      </div>
      <div className="mt-44 mx-2 sm:mx-24 flex flex-col justify-center items-center mb-20">
        {competence && (
          <div className="w-full">
            <h1 className="text-4xl font-semibold mb-5">{competence.name}</h1>
            <p className="text-customOrange-100 mb-10">
              {competence.categories}
            </p>
            <img
              src={competence.mainImage}
              alt="image principale"
              className="w-full sm:h-96 object-cover rounded-2xl my-4 object-top"
            />

            <h2 className="text-4xl font-semibold mt-10">Contexte</h2>
            <div className="mt-5 mb-10">{competence.description}</div>
            <div className="mt-5 mb-10">
              {competence.contenu.split("/n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
            <h2 className="text-4xl font-semibold mb-10">Travail effectué</h2>
            <div className="mt-5 mb-10">
              {competence.travailEffectue.split("/n").map((line, index) => {
                return (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
            {competence.lien && (
              <div>
                <h2 className="text-4xl font-semibold mb-10">Lien</h2>
                <Link
                  className="hover:underline hover:text-customOrange-100 transition-colors"
                  to={competence.lien}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {competence.lien}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </motion.div>
  );
}

export default Competences;
