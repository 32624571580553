import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import Bubble from "../components/Bubble";
import Parcours from "../components/Parcours.js";
function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col"
    >
      <div className="flex flex-col items-center">
        <Bubble height={600} width={600} top={10} />
      </div>
      <div className="mt-44 mx-2 sm:mx-24 flex flex-col justify-center items-center">
        <h1 className="text-2xl w-fit sm:text-8xl font-bold my-20">A Propos</h1>
      </div>

      <div className="w-full flex justify-center flex-col items-center mb-8">
        <div className="sm:w-3/5 w-11/12 border-customOrange-100 rounded-2xl border-r border-b flex p-4 bg-black bg-opacity-50 md:flex-row flex-col items-center">
          <img src="../ID.png" className="h-full rounded-lg w-1/4" />
          <div className="md:ml-8 py-4 flex flex-col justify-between">
            <h3 className="my-4 text-xl">Qui suis-je ?</h3>
            <p className="md:text-lg text-sm">
              Bonjour ! Je m'appelle{" "}
              <span className="text-customOrange-100">Corentin Casset</span>, je
              suis un Développeur Full-Stack français. Lorem ipsum, dolor sit
              amet, biggus erectus pero pequeno banana. Missa like alpacas.
            </p>
          </div>
        </div>
      </div>

      <Parcours />

      <Footer />
    </motion.div>
  );
}

export default About;
