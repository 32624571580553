import React from "react";
import Button from "./Button";
function Contact({ img }) {
  return (
    <div className="flex flex-col lg:my-16 lg:mt-10 mx-2 gap-8 gl:gap-20 mt-16">
      <h2 className="text-2xl sm:text-4xl text-center sm:text-left font-semibold mb-8">
        Me Contacter
      </h2>
      <div className="w-full flex justify-center">
        <div className="w-4/5 border-customOrange-100 rounded-2xl border-r border-b flex p-4 bg-black bg-opacity-50 md:flex-row flex-col items-center">
          <img
            src={img || "../photoid.JPG"}
            className="h-full rounded-lg w-1/12 object-cover hidden md:block"
          />
          <div className="md:ml-8 md:mt-16 flex flex-col justify-between">
            <p className="lg:text-2xl md:text-lg text-sm">
              Vous avez un projet en tête ? N'hésitez pas à me contacter pour
              qu'on puisse en discuter !
            </p>
            <div className="flex justify-end items-center sm:justify-end gap-4 flex-col mt-4 lg:flex-row">
              <Button href={"mailto:corentin.casset@outlook.com"}>
                corentin.casset@outlook.com
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
