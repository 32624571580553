import React, { useState } from "react";
import { motion } from "framer-motion";
import Bubble from "../components/Bubble";
import competences from "../database/competences.json";
import Card from "../components/Card";
function Projets() {
  const [isActive, setIsActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const skills = [
    "Javascript",
    "React",
    "Tailwind",
    "Scss",
    "Wordpress",
    "React Native",
    "Python",
  ];
  const handleCheckboxChange = (event, category) => {
    if (event.target.checked) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory("");
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col"
    >
      <div className="flex flex-col items-center">
        <Bubble height={600} width={600} top={10} />
      </div>
      <div className="mt-44 mx-2 sm:mx-24 flex flex-col justify-center items-center">
        <h1 className="text-2xl w-fit sm:text-8xl font-bold my-20 text-center">
          Mes Projets
        </h1>

        <div className="flex flex-col w-full mb-16">
          <div className="flex gap-4 mb-4">
            <img src="/filter.svg" alt="img" />{" "}
            <p className="lg:text-3xl text-lg">Filtres</p>
          </div>
          <div className="flex flex-wrap">
            {skills.map((skill, index) => (
              <div
                key={index}
                className={`flex gap-4 w-fit p-2 ${
                  isActive
                    ? "outline outline-customOrange-100 rounded-md outline-[2px]"
                    : ""
                }`}
              >
                <input
                  id={skill}
                  type="checkbox"
                  onChange={(event) => handleCheckboxChange(event, skill)}
                />
                <label htmlFor={skill}>{skill}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center gap-8 flex-wrap items-center">
          {competences
            .filter((competence) => {
              // Si aucune catégorie n'est sélectionnée, affichez tous les projets
              if (!selectedCategory) return true;

              // Sinon, affichez seulement les projets qui contiennent la catégorie sélectionnée
              return competence.categories
                .split(", ")
                .includes(selectedCategory);
            })
            .map((competence, index) => (
              <div key={index}>
                <Card
                  title={competence.name}
                  width={290}
                  height={500}
                  image={competence.mainImage}
                  linkPath={`/projets/` + competence.id}
                  categories={competence.categories.split(", ")}
                  content={competence.description}
                />
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  );
}

export default Projets;
