import React, { useEffect, useState } from "react";

function CompetenceCard({ img, title, progress }) {
  const [hovered, setHovered] = useState(false);
  const radius = 35;
  const circumference = 2 * Math.PI * radius;
  const [strokeDashoffset, setStrokeDashoffset] = useState(circumference);

  const handleMouseEnter = () => {
    setHovered(true);
    setStrokeDashoffset(circumference - (progress / 100) * circumference);
  };
  const handleMouseLeave = () => {
    setHovered(false);
    setStrokeDashoffset(circumference);
  };

  return (
    <div
      className="w-52 h-52 bg-black bg-opacity-50 flex flex-col items-center justify-between border-customOrange-100 rounded-2xl border-r border-b p-5 relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`transition-all duration-500 ease-in-out transform ${
          hovered ? "-translate-y-5 opacity-0" : ""
        }`}
      >
        <img src={img} alt={title} height={112} width={112} />
        <h3 className="text-center mt-4">{title}</h3>
      </div>

      <div
        className={`absolute top-0 bottom-0 left-0 right-0 h-full transition-all duration-500 ease-in-out transform flex items-center justify-center ${
          hovered
            ? "translate-y-0 h-full opacity-100 delay-50"
            : "translate-y-20 h-full opacity-0"
        }`}
      >
        <svg className="progress-ring" width="80" height="80">
          <circle
            stroke="black"
            strokeWidth="6"
            fill="transparent"
            r={radius}
            cx="40"
            cy="40"
          />
          <circle
            className="progress-ring__circle"
            stroke="#FFB87A"
            strokeWidth="6"
            fill="transparent"
            r={radius}
            cx="40"
            cy="40"
            strokeLinecap="round"
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset: strokeDashoffset,
              transition: "stroke-dashoffset 500ms cubic-bezier(0.4, 0, 1, 1)",
              transform: "rotate(-90deg)",
              transformOrigin: "50% 50%",
            }}
          />
        </svg>
        <p className="absolute text-white">{progress}%</p>
      </div>
    </div>
  );
}

export default CompetenceCard;
