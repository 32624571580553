import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <div
      className={`z-50 transition-all w-full flex justify-center sm:justify-end items-center md:px-20 gap-4 fixed ${
        isScrolled ? "bg-black bg-opacity-50 backdrop-blur-2xl h-20" : "h-40"
      }`}
    >
      <Link to="/" className="p-5 text-sm sm:text-base">
        Accueil
      </Link>
      <Link to="/a-propos" className="p-5 text-sm sm:text-base">
        A propos
      </Link>
      <Link to="/projets" className="p-5 text-sm sm:text-base">
        Projets
      </Link>
    </div>
  );
}

export default Navbar;
