import React, { useEffect, useState } from "react";
import "./Bubble.css";

function Bubble({ width, height, left, top, rotate }) {
  const style = {
    rotate: `${rotate}deg`,
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: "hsla(0,100%,50%,1)",
    backgroundImage: `
        radial-gradient(at 40% 20%, hsla(28,100%,74%,1) 0px, transparent 50%),
        radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0px, transparent 50%),
        radial-gradient(at 0% 50%, hsla(355,100%,93%,1) 0px, transparent 50%),
        radial-gradient(at 67% 58%, hsla(340,100%,76%,1) 0px, transparent 50%),
        radial-gradient(at 0% 100%, hsla(22,100%,77%,1) 0px, transparent 50%),
        radial-gradient(at 80% 100%, hsla(242,100%,70%,1) 0px, transparent 50%),
        radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0px, transparent 50%)
      `,
    borderRadius: "53% 47% 31% 69% / 55% 27% 73% 45% ",
    left: `${left}%`,
    top: `${top}%`,
  };
  return (
    <div
      className=" blur-3xl opacity-60 bubble fixed -z-10"
      style={style}
    ></div>
  );
}

export default Bubble;
