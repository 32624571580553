import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Competences from "./pages/Competences";
import About from "./pages/About";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar";
import Projets from "./pages/Projets";
import Mentions from "./pages/Mentions";
function App() {
  return (
    <Router>
      <Navbar />
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/projets/:id" element={<Competences />} />
          <Route path="/projets" element={<Projets />} />
          {/* <Route path="/mentions-legales" element={<Mentions />} /> */}
        </Routes>
      </AnimatePresence>
    </Router>
  );
}

export default App;
