import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Button({ children, icon, href }) {
  return (
    <Link to={href}>
      <div className="p-2 px-4 bg-none border-customOrange-100 border w-fit rounded-lg hover:bg-customOrange-100 hover:text-black transition-all hover:cursor-pointer text-lg flex items-center gap-4">
        <p className="text-sm sm:text-base">{children}</p>
        {icon && (
          <div className="p-2 rounded-full outline outline-1 outline-customOrange-100 flex items-center justify-center">
            <FontAwesomeIcon
              icon={icon}
              height={10}
              width={16}
              color="#FFB87A"
            />
          </div>
        )}
      </div>
    </Link>
  );
}

export default Button;
