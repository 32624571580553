import React from "react";
import { useEffect, useState } from "react";
import Bubble from "../components/Bubble";
import Button from "../components/Button";
import { motion } from "framer-motion";
import Competences from "../components/Competences";
import Realisations from "../components/Realisations";
import Contact from "../components/Contact.js";
import Footer from "../components/Footer";
function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  if (isLoading) {
    return (
      <div className="flex w-3/4 items-center justify-center">
        <div className="ball one"></div>
        <div className="ball two"></div>
        <div className="ball three"></div>
      </div>
    );
  }
  if (isLoading === false) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col"
      >
        <div className="flex flex-col items-center">
          <Bubble height={600} width={600} top={10} />
        </div>
        <div className="mt-44 mx-2 sm:mx-24 flex flex-col justify-center items-center">
          <h1 className="text-2xl w-fit sm:text-8xl font-bold my-20 text-center">
            Corentin Casset
          </h1>
          <p className="text-center text-2xl sm:text-4xl font-bold mb-24">
            Développeur Full-Stack
          </p>

          <div className="sm:w-3/5 w-11/12 border-customOrange-100 rounded-2xl border-r border-b flex p-4 bg-black bg-opacity-50 md:flex-row flex-col items-center mb-16">
            <img
              src="ID.png"
              className="rounded-lg w-1/4 h-36 object-cover object-center"
            />
            <div className="md:ml-8 md:mt-16 flex flex-col justify-between">
              <p className="lg:text-2xl md:text-lg text-sm">
                Bonjour ! Je m'appelle{" "}
                <span className="text-customOrange-100">Corentin Casset</span>,
                je suis un Développeur Full-Stack français. Etudiant en 2ème
                année à Digital Campus, je recherche une alternance.
              </p>
              <div className="flex justify-end items-center sm:justify-end gap-4 flex-col mt-4 lg:flex-row">
                <Button href={"/projets"}>Découvrir mon travail</Button>
                <Button href={"mailto:corentin.casset@outlook.com"}>
                  Prendre contact
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Competences />
        <Realisations />
        <div className="lg:mx-48">
          <Contact />
        </div>
        <Footer />
      </motion.div>
    );
  }
}

export default Home;
