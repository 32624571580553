"use client";

import React, { useState, useRef, useEffect } from "react";
import "./Card.css";
import { Link } from "react-router-dom";

function Card({ width, height, image, linkPath, categories, content, title }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cardRef = useRef(null);
  const [center, setCenter] = useState({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState({ width: 384, height: 80 });
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [cardStyle, setCardStyle] = useState({
    transition: "all 0.5s ease",
    // minWidth: `${width}px`,
    // minHeight: `${height}px`,
    // maxWidth: `${width}px`,
    // maxHeight: `${height}px`,
    backgroundSize: "contain",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "7px",
  });

  const threshold = 20;
  const strength = 100;

  const rotate = (cursorPosition, centerPosition) => {
    if (cursorPosition - centerPosition >= 0) {
      return cursorPosition - centerPosition >= threshold
        ? threshold
        : cursorPosition - centerPosition;
    } else {
      return cursorPosition - centerPosition <= -threshold
        ? -threshold
        : cursorPosition - centerPosition;
    }
  };

  const handleMouseMove = (e) => {
    if (isClicked === false) {
      setImageSize({
        width: `${width * 1.2}`,
        height: `${height * 1.2}`,
      });
      setIsHovered(true);

      const style = {
        transform: `perspective(1000px)
        rotateY(${rotate(e.clientX, center.x) / 1.75}deg)
        rotateX(${-rotate(e.clientY, center.y) / 1.75}deg)`,
        // minWidth: `${width}px`,
        // minHeight: `${height}px`,
        // maxWidth: `${width}px`,
        // maxHeight: `${height}px`,
        boxShadow: `${-rotate(e.clientX, center.x)}px ${-rotate(
          e.clientY,
          center.y
        )}px 80px 0px rgba(255, 255, 255, 0.2)`,
        backgroundSize: "contain", // cover the entire area of the div
        backgroundPosition: "top", // center the image
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderRadius: "7px",
        transition: "all 0.5s ease",
      };
      setCardStyle(style);
    }
  };

  const handleMouseLeave = () => {
    if (isClicked === false) {
      setTimeout(() => {
        setCardStyle(style);
      }, 1000);
      const style = {
        transform: "perspective(500px)",
        // minWidth: `${width}px`,
        // minHeight: `${height}px`,
        // maxWidth: `${width}px`,
        // maxHeight: `${height}px`,
        filter: "brightness(1)",
        boxShadow: "0 0 0 0 rgba(255, 255, 255, 0.2)", // set the background image
        backgroundSize: "contain", // cover the entire area of the div
        backgroundPosition: "top", // center the image
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderRadius: "7px",
        transition: "all 0.5s ease",
      };
      setIsHovered(false);
    }
  };

  useEffect(() => {
    if (!cardRef.current) return;
    const rect = cardRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    setCenter({ x: centerX, y: centerY });

    window.addEventListener("resize", () => {
      const rect = cardRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      setCenter({ x: centerX, y: centerY });
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <Link
      to={linkPath}
      id="card"
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={cardStyle}
      className={`border border-l-0 border-t-0 border-customOrange-100 backdrop-blur-xl flex flex-col p-5 cursor-pointer items-center transition-all duration-500 ease-in-out lg:w-96 w-72`}
    >
      <div
        className={`w-full h-full ${isHovered ? "box" : ""} absolute top-0`}
      ></div>
      <img
        src={image}
        width={imageSize.width}
        height={imageSize.height}
        className={``}
      />
      <p className="pb-4 pt-4 header w-full text-xl">{title}</p>
      <div className="flex flex-row w-full gap-2">
        {categories.map((category) => (
          <span className="category text-left text-customOrange-100 text-sm">
            {category}
          </span>
        ))}
      </div>
      {isMobile && (
        <span
          className="content text-left w-full h-full"
          style={{
            opacity: isMobile ? 1 : undefined,
            transform: isMobile ? "translateY(0)" : undefined,
            zIndex: isMobile ? 0 : undefined,
          }}
        >
          {content}
        </span>
      )}
      {!isMobile && (
        <span className="content text-left w-full h-full">{content}</span>
      )}
    </Link>
  );
}

export default Card;
