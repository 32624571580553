import React from "react";

function ParcoursDate({ date, titre, lieu, description }) {
  return (
    <div>
      <div className="flex gap-8">
        <p>{date}</p>
        <div className="flex flex-col items-center gap-2">
          <div className="h-8 w-8 rounded-full bg-customOrange-100 aspect-square"></div>
          <div className="w-1 h-5/6 bg-customOrange-100"></div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-base sm:text-lg">{titre}</h3>
          <p className="text-xs sm:text-sm text-customOrange-100">{lieu}</p>
          <p className="text-sm sm:text-base max-w-[60vw]">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default ParcoursDate;
