import React from "react";
import CompetenceCard from "./CompetenceCard";

function Competences() {
  return (
    <div className="lg:mx-48 lg:my-24 mx-2 lg:mt-44 flex flex-col gap-12">
      <h2 className="text-2xl sm:text-4xl text-center sm:text-left font-semibold mb-10">
        Mes Compétences
      </h2>

      <div className="w-full flex justify-center flex-col items-center sm:flex-row sm:flex-wrap gap-4 mb-10">
        <CompetenceCard
          title={"Html/Css"}
          img={"html-css.svg"}
          progress={100}
        />
        <CompetenceCard
          title={"Javascript"}
          img={"javascript.svg"}
          progress={80}
        />
        <CompetenceCard title={"React"} img={"react.svg"} progress={70} />
        <CompetenceCard title={"Tailwind"} img={"tailwind.svg"} progress={90} />
        <CompetenceCard
          title={"Bootstrap"}
          img={"bootstrap.svg"}
          progress={75}
        />
        <CompetenceCard title={"SCSS"} img={"scss.png"} progress={30} />
        <CompetenceCard title={"PHP"} img={"php.svg"} progress={85} />
        <CompetenceCard
          title={"Wordpress"}
          img={"wordpress.svg"}
          progress={90}
        />
        <CompetenceCard title={"MySQL"} img={"mysql.svg"} progress={80} />
        <CompetenceCard
          title={"React Native"}
          img={"react-native.svg"}
          progress={35}
        />
        <CompetenceCard title={"Python"} img={"python.svg"} progress={75} />
      </div>
    </div>
  );
}

export default Competences;
