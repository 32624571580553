import React from "react";
import ParcoursDate from "./ParcoursDate";
import Button from "./Button";
import Contact from "./Contact";
function Parcours() {
  return (
    <div className="lg:mx-48 lg:my-24 mx-2 lg:mt-44 flex flex-col gap-12">
      <h2 className="text-2xl sm:text-4xl text-center sm:text-left font-semibold mb-10">
        Mon Parcours
      </h2>
      <div className="mx-2 flex flex-col gap-4 items-center mb-10">
        <ParcoursDate
          date={"2023"}
          description={
            "je suis un Développeur Full-Stack français. Lorem ipsum, dolor sit amet, biggus erectus pero pequeno banana. Missa like alpacas.je suis un Développeur Full-Stack français. Lorem ipsum, dolor sit amet, biggus erectus pero pequeno banana. Missa like alpacas.je"
          }
          lieu={"Digital Campus"}
          titre={"Bachelor Chef de projet digital"}
        />
        <ParcoursDate
          date={"2023"}
          description={
            "je suis un Développeur Full-Stack français. Lorem ipsum, dolor sit amet, biggus erectus pero pequeno banana. Missa like alpacas.je suis un Développeur Full-Stack français. Lorem ipsum, dolor sit amet, biggus erectus pero pequeno banana. Missa like alpacas.je"
          }
          lieu={"Digital Campus"}
          titre={"Bachelor Chef de projet digital"}
        />
        <ParcoursDate
          date={"2023"}
          description={
            "je suis un Développeur Full-Stack français. Lorem ipsum, dolor sit amet, biggus erectus pero pequeno banana. Missa like alpacas.je suis un Développeur Full-Stack français. Lorem ipsum, dolor sit amet, biggus erectus pero pequeno banana. Missa like alpacas.je"
          }
          lieu={"Digital Campus"}
          titre={"Bachelor Chef de projet digital"}
        />
      </div>

      <h2 className="text-2xl sm:text-4xl text-center sm:text-left font-semibold mb-4">
        Mon CV
      </h2>
      <div className="w-full flex justify-center">
        <div className="w-full sm:w-4/5 border-customOrange-100 rounded-2xl border-r border-b flex p-4 bg-black bg-opacity-50 md:flex-row flex-col items-center md:justify-between">
          <div className="md:ml-8 mt-4 flex flex-col justify-between">
            <p className="text-customOrange-100 font-semibold w-fit sm:w-auto">
              Intéressé par mon profil ?
            </p>
            <p className="lg:text-2xl md:text-lg text-sm w-fit sm:w-auto ">
              Vous avez un projet en tête ? N'hésitez pas à me contacter pour
              qu'on puisse en discuter !
            </p>
            <div className="flex justify-end items-end sm:justify-end gap-4 flex-col mt-4 lg:flex-row">
              <a
                href="/CV.png"
                download
                className="p-2 px-4 bg-none border-customOrange-100 border w-fit rounded-lg hover:bg-customOrange-100 hover:text-black transition-all hover:cursor-pointer text-lg flex items-center gap-4"
              >
                Télécharger mon CV
              </a>
            </div>
          </div>
          <img
            src="../CV.png"
            className="h-full rounded-lg w-1/12 md:w-1/6 object-cover hidden md:block ml-24 p-4"
            style={{
              transform: "rotateY(-10deg) rotateX(10deg)",
              transition: "transform 1s",
            }}
          />
        </div>
      </div>
      <Contact img={"../logo512.png"} />
    </div>
  );
}

export default Parcours;
